






































































































import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {}
})

export default class PatternFilter extends Vue{
  private filterStage = "-1"

  // emits filterStage value to Cards.vue
  private passValue() {
    this.$emit('filter-stage', this.filterStage)
  }
}
