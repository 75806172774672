




























































































import {Component, Vue} from "vue-property-decorator";
import PatternFilter from '@/components/PatternFilter.vue'
import {round} from "@amcharts/amcharts4/.internal/core/utils/Math";

@Component({
  components: {PatternFilter}
})

export default class TagCards extends Vue {

  private placeholder = require('@/assets/pervaSafeLogo.png')
  private web_backend = 'https://hci.hochschule-trier.de:8443/files/'

  //Pattern Filter & Search Bar
  private patterns = []
  private search = ''

  /*Pattern Groups begin...*/
  private tags = []
  private groupNames = []
  private groups = [] as any
  private filteredGroups = [] as any
  private groupPatterns = [] as any
  private accordingPatterns = [] as any
  private ids = []
  private id = 0
  private groupCount = 0

  private accordingComments = []
  private averageRating = 0
  private allRatings = []
  private numberRatings = 0

  private patternNames = [] as any
  private commentPatterns = []
  private commentCount = [] as any
  private commentRatings = []
  private avratings = [] as any

  private getAverageRating(name: String) {
    return this.avratings[this.patternNames.indexOf(name)]
  }

  private getCommentsCount(name: String) {
    return this.commentCount[this.patternNames.indexOf(name)]
  }

  private getStageName(stage: number) {
    switch (stage) {
      case 0:
        return 'Created'
      case 1:
        return 'Consideration'
      case 2:
        return 'Candidate'
      case 3:
        return 'Approved'
      case 4:
        return 'Rejected'
      default:
        return '-'
    }
  }

  private tagName = ''

  created() {
    this.getData()
  }

  private getData() {
    // get Patterns
    Vue.axios.get(`${this.$store.state.root}/api/patterns`).then(resp => {
      this.patterns = resp.data
      this.patterns.forEach((pattern: any) => {
        pattern.image = this.web_backend + pattern.image
      })
      this.patternNames = resp.data.map((n: any) => n.name as [])
      this.ids = resp.data.map((c: any) => c.patternId as [])
    }).then(() => {
          // Get Comments
          Vue.axios.get(`${this.$store.state.root}/api/comments`).then(resp => {
            this.accordingComments = resp.data.filter((c: any) => (c.pattern == this.id))
            this.allRatings = this.accordingComments.map((int: any) => int.ratingRelevance) as []
            const sum = this.allRatings.reduce((a: any, b: any) => a + b, 0)
            this.averageRating = (sum / this.allRatings.length) || 0
            this.numberRatings = this.allRatings.length

            this.commentPatterns = resp.data.map((i: any) => i.pattern as [])
            this.commentRatings = resp.data.map((r: any) => r.ratingRelevance as [])

            for (let i = 0; i < this.ids.length; i++) {
              let tmp = 0
              for (let j = 0; j < this.commentPatterns.length; j++) {
                if (this.ids[i] == this.commentPatterns[j]) {
                  tmp++
                }
              }
              this.commentCount.push(tmp)
            }

            for (let i = 0; i < this.ids.length; i++) {
              let rating = 0
              let anz = 0
              for (let j = 0; j < this.commentPatterns.length; j++) {
                if (this.ids[i] == this.commentPatterns[j]) {
                  rating += this.commentRatings[j]
                  anz++
                }
              }
              let tmp = round(rating / anz, 1)
              if (Number.isNaN(tmp)) {
                tmp = 0
              }
              this.avratings.push(tmp)
            }
          }).then(() => {
            // get Tags
            Vue.axios.get(`${this.$store.state.root}/api/tags`).then(resp => {
              this.tags = resp.data
              this.groupNames = [...new Set(resp.data.map((x: any) => x.tag))] as []
              this.groupCount = this.groupNames.length
              this.groupNames.forEach(groupName => {
                let patternIds = this.tags.filter((x: any) => (x.tag === groupName)).map((x: any) => x.pattern)
                patternIds.forEach(patternId => {
                  if (this.patterns.filter((x: any) => (x.patternId === patternId))[0]) {
                    this.groupPatterns.push(this.patterns.filter((x: any) => (x.patternId === patternId))[0])
                  }
                })
                let group = {
                  name: groupName,
                  amountOfItems: this.groupPatterns.length,
                  patterns: this.groupPatterns
                  //patterns: this.patternPagination(this.groupPatterns)[0] //Paginierte Version
                }
                this.groups.push(group)
                this.filteredGroups.push(group)
                this.groupPatterns = []
              })
            }).then(() => {
              this.filterGroupNames()
            })
          })
        }
    )
  }

  private showAccordingPatterns(groupName: string) {
    this.tagName = groupName
    let group = this.filteredGroups.filter((x: any) => (x.name === groupName))[0]
    this.accordingPatterns = group.patterns
  }

  private shortenDetailText(text: String) {
    if (text == null || text == '' || text.length < 150) {
      return text
    }
    return text.slice(0, 150) + " ..."
  }

  private passPatternData(name: string) {
    this.$emit('emitPatternData', {patternName: name, webpage: this.tagName})
    this.getData()
  }

  /*...Pattern Groups end*/

  /*Search Bar begin...*/
  private filterGroupNames() {
    this.filteredGroups = this.groups.filter((c: any) => (c.name.toLowerCase().includes(this.search.toLowerCase().trim())))
    this.groupCount = this.filteredGroups.length
  }

  /*... Search Bar end */
}
